<template>
  <div style="margin-bottom: 50px">
    <div>
      <br />
      <v-card outlined class="body-card pa-4" v-if="agentData">
        <v-row justify="center" align="center">
          <v-col cols="12" md="2" style="text-align: -webkit-center">
            <v-img
              max-width="110px"
              class="ml-5 mt-0"
              :src="agentData.profileUrl"
            ></v-img>
          </v-col>
          <v-col cols="12" md="4">
            <span style="font-size: 22px"
              ><b>รหัสตัวแทน:</b> {{ agentData.agentNo }}</span
            >
            <br />
            <span style="font-size: 22px"
              ><b>ชื่อตัวแทน:</b> {{ agentData.firstName }}
              {{ agentData.lastName }}</span
            >
            <br />
            <span style="font-size: 22px"
              ><b>หมายเลขโทรศัพท์ตัวแทน:</b> {{ agentData.tel }}</span
            >
          </v-col>

          <!-- <v-col cols="12" md="4">
              <span style="font-size: 22px"
                ><b>อีเมลตัวแทน:</b> {{ agentData.email }}</span
              >
              <br />
              <span style="font-size: 22px"
                ><b>หมายเลขโทรศัพท์ตัวแทน:</b> {{ agentData.tel }}</span
              >
            </v-col> -->
        </v-row>
      </v-card>
      <v-row align="center" justify="center" no-gutters v-if="selling">
        <v-card class="text-center ml-0 backgroundcard pa-1">
          <h1 style="font-weight: 800" class="mt-4 white--text">ค้นหาเลข</h1>
          <h2 class="white--text">กรอกเลขที่ต้องการค้นหา</h2>
          <v-row class="" dense>
            <v-col cols="12">
              <v-btn-toggle v-model="toggle_exclusive" dense>
                <v-btn
                  @click="btnDisabled(4)"
                  class="px-2 py-2"
                  style="border: 1px solid #d5ab6173"
                  small
                >
                  ทั้งหมด
                </v-btn>

                <v-btn
                  @click="btnDisabled(3)"
                  class="px-2 py-2"
                  style="border: 1px solid #d5ab6173"
                  small
                >
                  3 ตัวหน้า
                </v-btn>

                <v-btn
                  @click="btnDisabled(2)"
                  class="px-2 py-2"
                  style="border: 1px solid #d5ab6173"
                  small
                >
                  3 ตัวท้าย
                </v-btn>

                <v-btn
                  @click="btnDisabled(1)"
                  class="px-2 py-2"
                  style="border: 1px solid #d5ab6173"
                  small
                >
                  2 ตัวท้าย
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="mt-2" no-gutters>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled6 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled6"
                :style="disabled6 ? `background-color:#80aedf` : ''"
                v-model="num1"
                id="num1"
                type="number"
                v-mask="'#'"
                placeholder="บ"
                solo
                hide-details
                @input="selectionHandler"
                @keyup="clickEvent(num1, 2)"
              />
            </v-col>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled5 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled5"
                :style="disabled5 ? `background-color:#80aedf` : ''"
                v-model="num2"
                id="num2"
                type="number"
                v-mask="'#'"
                placeholder="า"
                solo
                hide-details
                @select="selectionHandler"
                @keyup="clickEvent(num2, 3)"
              />
            </v-col>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled4 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled4"
                :style="disabled4 ? `background-color:#80aedf` : ''"
                v-model="num3"
                id="num3"
                type="number"
                v-mask="'#'"
                placeholder="ง"
                solo
                hide-details
                @keyup="clickEvent(num3, 4)"
              />
            </v-col>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled3 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled3"
                :style="disabled3 ? `background-color:#80aedf` : ''"
                v-model="num4"
                id="num4"
                type="number"
                v-mask="'#'"
                placeholder="ก"
                solo
                hide-details
                @keyup="clickEvent(num4, 5)"
              />
            </v-col>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled2 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled2"
                :style="disabled2 ? `background-color:#80aedf` : ''"
                v-model="num5"
                id="num5"
                type="number"
                v-mask="'#'"
                placeholder="อ"
                solo
                hide-details
                @keyup="clickEvent(num5, 6)"
              />
            </v-col>
            <v-col cols="2" md="1" sm="1" style="padding: 2px">
              <v-text-field
                class="centered-input inputPrice"
                :solo-inverted="disabled1 === true ? true : false"
                :maxlength="maxlength"
                :disabled="disabled1"
                :style="disabled1 ? `background-color:#80aedf` : ''"
                v-model="num6"
                id="num6"
                type="number"
                v-mask="'#'"
                placeholder="ก"
                solo
                hide-details
                @keyup="clickEvent(num6, 7)"
              />
            </v-col>
          </v-row>
          <h2 class="white--text my-4">เลือกลอตเตอรี่แบบชุด</h2>
          <v-row align="center" justify="center">
            <v-btn-toggle v-model="toggle_exclusive2" dense>
              <v-btn @click="lottoGroup = ''">ทั้งหมด</v-btn>
              <v-btn @click="lottoGroup = 'one'">ใบเดียว</v-btn>
              <v-btn @click="lottoGroup = 'many'">ชุดใหญ่</v-btn>
            </v-btn-toggle>
            <!-- <v-col cols="10" md="6" sm="6"
              ><v-select
                multiple
                chips
                v-model="lottoGroup"
                :items="[
                  {
                    name: 'ชุด 1 ใบ',
                    value: '1',
                  },
                  {
                    name: 'ชุด 2 ใบ',
                    value: '2',
                  },
                  {
                    name: 'ชุด 3 ใบ',
                    value: '3',
                  },
                  {
                    name: 'ชุด 4 ใบ',
                    value: '4',
                  },
                  {
                    name: 'ชุด 5 ใบ',
                    value: '5',
                  },
                  {
                    name: 'ชุด 6 ใบ',
                    value: '6',
                  },
                  {
                    name: 'ชุด 7 ใบ',
                    value: '7',
                  },
                  {
                    name: 'ชุด 8 ใบ',
                    value: '8',
                  },
                  {
                    name: 'ชุด 9 ใบ',
                    value: '9',
                  },
                  {
                    name: 'ชุด 10 ใบ',
                    value: '10',
                  },
                  {
                    name: 'ชุด 11 ใบ',
                    value: '11',
                  },
                  {
                    name: 'ชุด 12 ใบ',
                    value: '12',
                  },
                  {
                    name: 'ชุด 13 ใบ',
                    value: '13',
                  },
                  {
                    name: 'ชุด 14 ใบ',
                    value: '14',
                  },
                  {
                    name: 'ชุด 15 ใบ',
                    value: '15',
                  },
                ]"
                item-text="name"
                label="เลือกลอตเตอรี่แบบชุด"
                solo
                dense
                hide-details
              ></v-select>
            </v-col> -->
          </v-row>
          <v-row align="center" justify="center" class="mb-4">
            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-col cols="4" md="4" sm="12">
                  <v-btn
                    color="red"
                    large
                    block
                    style="color: white; width: 50%"
                    @click="clear()"
                    >ลบตัวเลข</v-btn
                  >
                </v-col>
                <v-col cols="8" md="8" sm="12">
                  <v-btn
                    class="searchbtn"
                    large
                    block
                    id="search"
                    @click="search()"
                    >ค้นหาตัวเลข</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
      <!-- <v-row
        align="center"
        justify="center"
        width="60%"
        class="text-center"
        no-gutters
      >
        <v-btn color="#ffff" class="ma-1">ทั้งหมด</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 1 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 2 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 3 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 4 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 5 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 6 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 7 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 8 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 9 ใบ</v-btn>
      <v-btn color="#ffff" class="ma-1">ชุด 10 ใบ</v-btn>
      </v-row> -->
      <br />
      <v-col cols="12" v-if="selling">
        <div class="text-center" style="color: #9b6810; font-size: 40px">
          ผลการค้นหา
        </div>
      </v-col>
      <v-row
        align="center"
        justify="center"
        width="100%"
        class="ma-4 mb-8"
        v-if="selling"
      >
        <v-col
          cols="6"
          md="3"
          sm="6"
          xs="3"
          v-for="(i, index) in lotteries"
          :key="index"
        >
          <v-card
            class="mx-0 text-center"
            max-width="96vw"
            max-height="auto"
            elevation="6"
            outlined
          >
            <div class="info1 font-weight-bold white--text">
              <!-- <p class="ma-0">{{ i.setAmt }} ใบ</p>
              <p class="ma-0">{{ i.setAmt * 6 }} ล้าน</p> -->
              <p style="font-size: 12px" class="ma-0">{{ i.setAmt }} ใบ</p>
              <p style="font-size: 12px" class="ma-0">
                {{ i.setAmt * 6 }} ล้าน
              </p>
            </div>
            <v-img :src="i.urlImage" lazy="loaded">
              <!-- <v-img
                max-width="150px"
                class="mx-auto mb-0 "
                src="@/assets/BKLCOVER.png"
              >
              </v-img> -->
            </v-img>
            <div class="info2">
              <v-img class="mx-auto mb-0" src="@/assets/logo/BKLTH.png">
              </v-img>
            </div>

            <!-- <v-btn
              block
              style="background-color: #00739c"
              dark
              @click="openLine()"
              ><v-icon>mdi-cart</v-icon> ติดต่อแอดมิน</v-btn
            > -->
          </v-card>
          <v-btn
            block
            class="font-weight-bold white--text mt-2"
            style="background-color: #2560a1; color: black"
            dark
            @click="addToCart(i)"
            ><v-icon>mdi-cart</v-icon> เพิ่มลงตะกร้า</v-btn
          >
        </v-col>
      </v-row>
      <v-col cols="12" v-if="lotteries && lotteries.length == 0" class="py-10">
        <h3 class="text-center" style="color: rgb(255 0 0)">
          ไม่พบลอตเตอรี่ที่ท่านค้นหา
        </h3>
      </v-col>
    </div>
    <v-row
      align="center"
      justify="center"
      width="100%"
      class="ma-4"
      v-if="!selling"
      style="margin-top: 50px !important"
    >
      <h3 class="text-center" style="color: rgb(255 0 0)">
        ยังไม่เปิดขายลอตเตอรี่
      </h3>
    </v-row>
  </div>
</template>

<script>
import ModaltermOfServices from "@/components/User/Modal/ModalTermofServices";
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
export default {
  data() {
    return {
      itemotp: "",
      disableLottery: false,
      selling: true,
      toggle_exclusive: undefined,
      disabled1: false,
      disabled2: false,
      disabled3: false,
      disabled4: false,
      disabled5: false,
      disabled6: false,
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      items: [
        {
          src: require("@/assets/banners/Asset113.png"),
        },
        {
          src: require("@/assets/banners/Asset115.png"),
        },
        {
          src: require("@/assets/banners/Asset116.png"),
        },
        {
          src: require("@/assets/banners/banner_aug_18.png"),
        },
      ],

      num1: null,
      num2: null,
      num3: null,
      num4: null,
      num5: null,
      num6: null,
      nums: [
        { num: 1, value: null },
        { num: 2, value: null },
        { num: 3, value: null },
        { num: 4, value: null },
        { num: 5, value: null },
        { num: 6, value: null },
      ],
      maxlength: 1,
      lotteries: [],
      userData: "",
      lottoGroup: [],
      window: {
        width: 0,
        height: 0,
      },
      agentCode: "",
      agentData: null,
      setAmt: [],
      checktype: true,
    };
  },
  watch: {
    // nums: {
    //   handler(array) {
    //     for (const i in array) {
    //       const idx = parseInt(i);
    //       console.log("array[i].value.length", array[i].value.length);
    //       if (array[i].value.length > 0 && array.length - 1 === idx) {
    //         document.getElementById("search").focus();
    //       } else if (array[i].value.length > 0) {
    //         console.log("บวก");
    //         this.nextFocus(array[i].num + 1);
    //       } else {
    //         console.log("ลบ");
    //         this.previousFocus(array[i].num - 1);
    //       }
    //     }
    //   },
    //   deep: true,
    // },
    // num1(val) {
    //   if (val) {
    //     this.nextFocus(2);
    //   }
    // },
    // num2(val) {
    //   if (val) {
    //     this.nextFocus(3);
    //   }
    //   if (!val) {
    //     this.previousFocus(1);
    //   }
    // },
    // num3(val) {
    //   if (val) {
    //     this.nextFocus(4);
    //   }
    //   if (!val) {
    //     this.previousFocus(2);
    //   }
    // },
    // num4(val) {
    //   if (val) {
    //     this.nextFocus(5);
    //   }
    //   if (!val) {
    //     this.previousFocus(3);
    //   }
    // },
    // num5: {
    //   handler(val) {
    //     console.log(val);
    //     if (val) {
    //       this.nextFocus(6);
    //     }
    //     if (!val) {
    //       this.previousFocus(4);
    //     }
    //   },
    // },
    // num6: {
    //   handler(val) {
    //     if (val) {
    //       console.log("num6", val);
    //     }
    //     if (!val) {
    //       this.previousFocus(5);
    //     }
    //   },
    // },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.lotteries = [];
    this.getLottery();
    this.getAgents();
  },
  components: {
    ModaltermOfServices,
  },
  methods: {
    selectionHandler(e) {
      console.log("e", e);
    },
    clickEvent(first, last) {
      if (first) {
        this.nextFocus(first, last);
      }
      if (!first) {
        this.previousFocus(last - 2);
      }
      // console.log("first", first, "last", last);
    },
    async getAgents() {
      let agentCode = this.$route.query.agentCode;
      // localStorage.setItem("agentCode", agentCode);
      var agentStorage = localStorage.getItem("agentCode");
      console.log("agentStorage", agentStorage);
      if (agentStorage != "undefined") {
        if (agentStorage != agentCode) {
          this.confirmChangeAgent();
        } else {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/` + agentStorage
          );
          this.agentData = response.data.data;
          console.log("response", response.data.data);
        }
      } else {
        console.log("agentCode", agentCode);

        if (agentCode) {
          this.agentCode = agentCode;
          localStorage.setItem("agentCode", this.agentCode);
          const agentResponse = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/agentCount/` + agentCode
          );
          console.log(agentResponse.data);
          if (agentResponse.data.response_status == "SUCCESS") {
            this.agentData = agentResponse.data.data;
          }
          console.log(this.agentData);
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/` + this.agentCode
          );
          this.agentData = response.data.data;
          console.log("response", response.data.data);
        }
      }
    },
    async confirmChangeAgent() {
      this.$swal({
        text: "ขณะนี้ ท่านกำลังกดซื้อกับตัวแทนท่านใหม่ หากต้องการซื้อกับตัวแทนท่านเดิม กดที่นี่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ซื้อกับตัวแทนท่านเดิม",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/` +
              localStorage.getItem("agentCode")
          );
          this.agentData = response.data.data;
          console.log("response", response.data.data);
        } else {
          let agentCode = this.$route.query.agentCode;
          localStorage.setItem("agentCode", agentCode);
          const agentResponse = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/agentCount/` + agentCode
          );
          console.log(agentResponse.data);
          if (agentResponse.data.response_status == "SUCCESS") {
            this.agentData = agentResponse.data.data;
          }
          console.log(this.agentData);
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/agents/` + agentCode
          );
          this.agentData = response.data.data;
          console.log("response", response.data.data);
        }
      });
      //updateput trx trxid = status success
    },
    btnDisabled(val) {
      if (val === 3) {
        this.num4 = "";
        this.num5 = "";
        this.num6 = "";
        this.disabled1 = true;
        this.disabled2 = true;
        this.disabled3 = true;
        this.disabled4 = false;
        this.disabled5 = false;
        this.disabled6 = false;
      } else if (val === 2) {
        // 3 ตัวหน้า
        this.num1 = "";
        this.num2 = "";
        this.num3 = "";
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = true;
        this.disabled5 = true;
        this.disabled6 = true;
      } else if (val === 1) {
        // สองตัวท้าย
        this.num1 = "";
        this.num2 = "";
        this.num3 = "";
        this.num4 = "";
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = true;
        this.disabled4 = true;
        this.disabled5 = true;
        this.disabled6 = true;
      } else {
        // เปิดหมด
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
        this.disabled5 = false;
        this.disabled6 = false;
      }
      // @click="btnDisabled(4)"
    },
    openLine() {
      window.open("https://lin.ee/msKr0ha");
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    clear() {
      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.num4 = "";
      this.num5 = "";
      this.num6 = "";
      // this.nums.forEach((nums) => (nums.value = null));
      this.lottoGroup = [];
    },
    nextFocus(first, val) {
      // console.log("nextfocus", val);
      // console.log("first", first);
      document.getElementById("num" + val).focus();
    },
    previousFocus(val) {
      // console.log("previousFocus", val);
      document.getElementById("num" + val).focus();
    },
    RegisterService() {
      this.$router
        .push({ path: "/step1?status=service&&type=create" })
        .catch(() => {});
    },
    RegisterProvider() {
      this.$router
        .push({ path: "/step1?status=provider&&type=create" })
        .catch(() => {});
    },
    OpenModal() {
      this.$EventBus.$emit("ModalPopUp");
    },
    async getLottery() {
      const responseConfigIsOpen = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/IS_OPEN`,
        {}
      );
      console.log("responseConfigIsOpen", responseConfigIsOpen.data.data);
      if (
        responseConfigIsOpen.data.data.value1 == 1 ||
        responseConfigIsOpen.data.data.value1 == "1"
      ) {
        this.selling = true;
      } else {
        this.selling = false;
      }

      const data = {
        position1: this.num6,
        position2: this.num5,
        position3: this.num4,
        position4: this.num3,
        position5: this.num2,
        position6: this.num1,
        stock: 1,
        setAmt: [],
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/searchByNumber`,
        data
      );
      //  const response = await this.axios.post(
      //   `${process.env.VUE_APP_API}/lottery/getAll`,
      //   data
      // );
      console.log("response", response.data.data);

      this.lotteries = response.data.data;
      // this.lotteries.forEach((lot) => {
      //   lot.urlImage = lot.urlImage.replace("20210425", "20210424");
      //   // console.log(lot.urlImage);
      // });
    },
    async search() {
      this.lotteries = [];

      const data = {
        // position1: this.nums[0].value,
        // position2: this.nums[1].value,
        // position3: this.nums[2].value,
        // position4: this.nums[3].value,
        // position5: this.nums[4].value,
        // position6: this.nums[5].value,
        position1: parseInt(this.num1),
        position2: parseInt(this.num2),
        position3: parseInt(this.num3),
        position4: parseInt(this.num4),
        position5: parseInt(this.num5),
        position6: parseInt(this.num6),
        stock: 1,
        setAmt: this.lottoGroup,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/lottery/searchByNumber`,
        data
      );
      console.log("response", response.data.data);

      this.lotteries = response.data.data;
    },
    async addToCart(lotto) {
      if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
        // this.StatusLogin = true;
        var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
        console.log("user", user);
        const data = {
          lotteryId: lotto.id,
          amount: 1,
          userId: user.user.id,
          lotteryNo: lotto.lotteryNo,
        };

        this.$store.dispatch("ActionsAddCars", data);
        console.log(data);

        // const auth = {
        //   headers: { Authorization: `Bearer ${user.user.token}` },
        // };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/cart/create`,
          data
        );
        console.log(response);
        console.log("add to cart", response.data.data);
        if (response.data.response_status === "SUCCESS") {
          await this.$swal.fire({
            icon: "success",
            title: "เพิ่มลอตเตอรี่ลงสู่ตะกร้าสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          this.disableLottery = true;
          await this.$router.push({ path: "/cart" }).catch(() => {});
        }
        // const index = this.lotteries.indexOf(lotto);
        // if (index > -1) {
        //   this.lotteries.splice(index, 1);
        // }
        // this.getLottery();
      } else {
        // this.StatusLogin = false;
        // await this.$swal.fire({
        //   icon: "error",
        //   title: "ไม่พบข้อมูลผู้ใช้ กรุณาสมัครสมาชิก",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        // await this.$router.push({ path: "/member" }).catch(() => {});
        await this.$swal.fire({
          icon: "success",
          html: "พบกับการเปิดตัว บางกอกลอตเตอรี่.com แพลตฟอร์มสลากดิจิทัลเอกชน ราคา 80 บาท เร็วๆ นี้ <br/>แอดไลน์เพิ่มเพื่อนไว้รอซื้อได้เลย คลิกที่นี่<br/><a href='https://bit.ly/3yRVDzc'>https://bit.ly/3yRVDzc</a>",
          showConfirmButton: true,
          // timer: 1500,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.H-Page1 {
  height: 30vh;
}
.H-Page2 {
  height: 10vh;
}
.H-Page3 {
  height: 50vh;
}
.initial {
  height: 80px;
  padding: 0 80px;
}
.info1 {
  width: 25%;
  margin-bottom: -35px;
  position: relative;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 15%;
  max-height: 15%;
  background: #2560a1;
  backdrop-filter: blur(4px);
  text-align: center;
  color: rgb(0, 0, 0);
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1;
  font-size: 20px;
  padding: 5px 0;
}
.info2 {
  margin-top: -27%;
  margin-bottom: 15px;
  margin-left: 48%;
  margin-right: 10%;
  // padding-top: 16px;
  // padding-bottom: 18px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 5%;
  max-height: 10%;
  background: #f3e0bf73;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(0.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}
</style>

<style lang="css" scoped>
.v-text-field >>> input {
  /* font-size: 1.2rem; */
  font-weight: 100;
  /* text-transform: capitalize; */
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.centered-input >>> input {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  /* margin: 0.5px; */
  /* padding-right: 2px;
  padding-left: 2px; */
  padding: 0px;
}

.body-card {
  background-image: linear-gradient(
    to left,
    #e8be50 0%,
    #f6e094 35%,
    #f9f295 50.39%,
    #e5c24c 100%
  );
  margin-bottom: 0;
}
.backgroundcard {
  /* background-image: linear-gradient(
    to left,
    #2560a1 0%,
    #80aedf 50.39%,
    #2560a1 100%
  ); */
  /* background-color: #80aedf; */
  background-color: #2560a1;
  width: 96%;
  margin-top: 40px;
}
/* .v-text-field >>> label {
    font-size: 0.8em;
}
.v-text-field >>> button {
    font-size: 0.8em;
} */
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.searchbtn {
  width: 50%;
  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
}
</style>
